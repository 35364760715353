import { Box, Input, InputProps } from '@mui/material'
import React from 'react'
import { LabelTypography } from '../common'

export interface InputFieldProps extends Omit<InputProps, 'onChange' | 'type'> {
  label?: string
  labelIcon?: React.ReactNode
  onChange: (value: string) => void
  value?: string
  scale?: 'small' | 'medium' | 'large'
}

export const InputField: React.FC<InputFieldProps> = ({
  label = '',
  onChange,
  value,
  scale = 'medium',
  labelIcon,
  ...rest
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <LabelTypography variant="subtitle2">
          {label} {rest.required && '*'} {labelIcon}
        </LabelTypography>
      )}
      <Input
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
        sx={{
          boxSizing: 'border-box',
          background: '#FDFDFD',
          border: '1px solid rgba(161, 171, 183, 0.3)',
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          position: 'relative',
          display: 'flex',
          overflow: 'hidden',

          '& input': {
            width: '100%',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize:
              scale === 'large' ? '20px' : scale === 'small' ? '14px' : '16px',
            lineHeight: '20px',
            color: '#16191C',
            padding: '0px'
          }
        }}
      />
    </Box>
  )
}
