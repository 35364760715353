import { MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material'
import React from 'react'
import { FormControlStyled, LabelTypography, SelectOption } from '../common'

interface SelectProps<T> {
  name?: string
  label?: string
  options?: SelectOption<T>[]
  disabled?: boolean
  required?: boolean
  onChange: (value: T) => void
  value?: T
}

export const Select = <T,>({
  name,
  label,
  options = [],
  disabled = false,
  required = false,
  onChange,
  value
}: React.PropsWithChildren<SelectProps<T>>) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as T)
  }

  return (
    <div className="SelectOptionsLabel">
      {!!label && (
        <LabelTypography variant="subtitle2">{label}</LabelTypography>
      )}

      <FormControlStyled size="small" sx={{ width: '100%' }}>
        <MuiSelect
          name={name}
          displayEmpty
          disabled={disabled}
          required={required}
          value={value as string}
          onChange={handleChange}
          sx={{ width: '100%', height: '33px' }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.label as string}
              value={option.value as string}
            >
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControlStyled>
    </div>
  )
}
