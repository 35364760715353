import { Box, Typography } from '@mui/material'
import { SearchField } from 'components/SearchField/SearchField'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { BoxRowBasic, ViewerBottomHeaderFooter } from 'components/common'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getSimpleIngredientNutrients,
  updateSimpleIngredient,
  updateSimpleIngredientNutrient
} from 'state/simple_ingredients/SimpleIngredientsSlice'
import { toTableNutrientsRowGroup } from './NutrientsMapper'
import { NutrientRowGroupProps } from './components/NutrientRowGroup'
import NutrientsTable from './components/NutrientTable'
import { IngredientViewerPanelContent } from '../../IngredientViewStyles'
import { InputWithAdornment } from 'components/InputWithAdornment/InputWithAdornment'
import { useDebouncedCallback } from 'use-debounce'
import {
  getLiquidMeasurements,
  getMeasurements,
  getSolidMeasurements
} from 'state/simple_ingredients/measurements/SimpleIngredientMeasurementsSlice'

interface NutrientsProps {
  ingredientId: string
  disabled?: boolean
}

export const Nutrients: React.FC<NutrientsProps> = ({
  ingredientId,
  disabled = false
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const { showError } = React.useContext(SnackbarContext)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const currentIngredientNutrients = useAppSelector(
    (state) => state.simpleIngredients.currentSimpleIngredientNutrients
  )
  const selectedIngredient = useAppSelector(
    (state) => state.simpleIngredients.selectedSimpleIngredient
  )
  const measurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.measurements
  )
  const liquidMeasurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.liquidMeasurements
  )
  const solidMeasurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.solidMeasurements
  )

  const [searchQuery, setSearchQuery] = React.useState<string>('')

  /**
   * Load the ingredient nutrients.
   */
  React.useEffect(() => {
    if (ingredientId) {
      void dispatch(
        getSimpleIngredientNutrients({
          companyId: currentCompany.id,
          simpleIngredientId: ingredientId
        })
      )
    }
  }, [ingredientId])

  // load measurements
  React.useEffect(() => {
    if (solidMeasurements === undefined) {
      void dispatch(
        getSolidMeasurements({
          companyId: currentCompany.id
        })
      )
    }
    if (liquidMeasurements === undefined) {
      void dispatch(
        getLiquidMeasurements({
          companyId: currentCompany.id
        })
      )
    }
    if (measurements === undefined) {
      void dispatch(
        getMeasurements({
          companyId: currentCompany.id
        })
      )
    }
  }, [solidMeasurements, liquidMeasurements, measurements, currentCompany.id])

  const onMeasurementOrScalePreferenceChange = useDebouncedCallback(
    (measurementPreferenceId?: string, scalePreference?: number) => {
      if (
        (selectedIngredient?.measurementPreference?.id ===
          measurementPreferenceId &&
          selectedIngredient?.scalePreference === scalePreference) ||
        !scalePreference
      ) {
        return
      }
      void dispatch(
        updateSimpleIngredient({
          companyId: currentCompany.id,
          simpleIngredientId: ingredientId,
          measurementPreferenceId,
          scalePreference
        })
      )
    },
    500
  )

  const onAmountChange = React.useCallback(
    (nutrientId: string, amount: number) => {
      if (ingredientId) {
        void dispatch(
          updateSimpleIngredientNutrient({
            companyId: currentCompany.id,
            simpleIngredientId: ingredientId,
            nutrientType: nutrientId, // Done on purpose. To be replaced by Nutrient Id once API gets updated.
            amount
          })
        )
          .unwrap()
          .catch(() => {
            showError(`Failed to update nutrient`)
          })
      }
    },
    []
  )

  const filterNutrientRowGroups = (
    nutrientRowGroups: NutrientRowGroupProps[],
    searchQuery: string
  ) => {
    return nutrientRowGroups.reduce((acc: NutrientRowGroupProps[], nrg) => {
      const filteredNutrientRows = nrg.nutrientRows.filter((nr) =>
        nr.nutrient.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      if (filteredNutrientRows.length > 0) {
        // Only add nrg to the accumulator if filteredNutrientRows is not empty.
        acc.push({ ...nrg, nutrientRows: filteredNutrientRows })
      }
      return acc
    }, [])
  }

  const nutrientTableRowGroups = React.useMemo(() => {
    if (currentIngredientNutrients) {
      const result = toTableNutrientsRowGroup(
        currentIngredientNutrients,
        onAmountChange
      )
      if (searchQuery) {
        return filterNutrientRowGroups(result, searchQuery)
      }
      return result
    }
    return []
  }, [currentIngredientNutrients, searchQuery])

  const onNutrientSearchChange = React.useCallback((searchQuery: string) => {
    setSearchQuery(searchQuery)
  }, [])

  const getMeasurementIdFromMeasurementUnit = (unit: string) => {
    if (!measurements) {
      return
    }
    return measurements.find((m) => m.unit === unit)?.id
  }
  const getMeasurementUnitFromMeasurementId = (measurementId?: string) => {
    if (!measurements || !measurementId) {
      return 'g'
    }
    return measurements.find((m) => m.id === measurementId)?.unit || 'g'
  }

  const measurementsUnitOptions = React.useMemo(() => {
    if (!measurements) {
      return []
    }
    const allMeasurements = measurements.filter(
      (m) => m.unit !== selectedIngredient?.measurementPreference?.unit
    )

    const volumetricUnitsSupported = !!(
      selectedIngredient?.calculatedDensity ||
      selectedIngredient?.densityOverride
    )

    const filteredMeasurements = volumetricUnitsSupported
      ? allMeasurements
      : solidMeasurements || []

    return filteredMeasurements.map((m) => m.unit)
  }, [
    measurements,
    solidMeasurements,
    liquidMeasurements,
    selectedIngredient?.measurementPreference?.unit,
    selectedIngredient?.calculatedDensity,
    selectedIngredient?.densityOverride
  ])

  return (
    <>
      <IngredientViewerPanelContent ref={ref}>
        <Box sx={{ padding: '20px 20px 0px 20px' }}>
          <SearchField
            placeholder="Search nutrients"
            onChange={onNutrientSearchChange}
            sx={{ width: '100%' }}
          />
          {searchQuery && nutrientTableRowGroups.length === 0 && (
            <Typography sx={{ margin: '10px 0px', textAlign: 'center' }}>
              No nutrient matched your search
            </Typography>
          )}
        </Box>
        <NutrientsTable
          nutrientTableRowGroups={nutrientTableRowGroups}
          disabled={disabled}
          loading={!searchQuery && nutrientTableRowGroups.length === 0}
        />
      </IngredientViewerPanelContent>

      <ViewerBottomHeaderFooter data-footer>
        <BoxRowBasic gap={'5px'} style={{ alignItems: 'center' }}>
          <Typography color={'secondary'}>Based on</Typography>
          <InputWithAdornment
            initialValue={
              selectedIngredient?.scalePreference
                ? selectedIngredient.scalePreference
                : 100
            }
            initialUnit={getMeasurementUnitFromMeasurementId(
              selectedIngredient?.measurementPreference?.id
            )}
            unitOptions={measurementsUnitOptions}
            getValueAndUnit={(value, unit) => {
              onMeasurementOrScalePreferenceChange(
                getMeasurementIdFromMeasurementUnit(unit),
                value
              )
            }}
            disabled={disabled}
            inputStyle={{ height: '30px' }}
          />
        </BoxRowBasic>
      </ViewerBottomHeaderFooter>
    </>
  )
}
