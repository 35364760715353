import { Box, Typography } from '@mui/material'
import { langDisplaySorter } from 'common/utils'
import {
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import React from 'react'
import { Statement } from './components/Statement'
import { AllergensPerLanguage } from 'models/Allergen'

export interface StatementsProps {
  ingredientsStatement: IngredientStatementProps
  allergensStatement: AllergensStatementProps
  labelDescription: LabelDescriptionProps
}

export interface AllergensStatementProps {
  allergens: AllergensPerLanguage
  statementsOverride: FormulaNutritionFactLabelLangSpecificFields
  suggestedAllergenStatement: FormulaNutritionFactLabelLangSpecificFields
  allergenStatementPrefix: FormulaNutritionFactLabelLangSpecificFields
}

export interface IngredientStatementProps {
  statement: FormulaNutritionFactLabelLangSpecificFields
  suggestedStatement: FormulaNutritionFactLabelLangSpecificFields
  prefix: FormulaNutritionFactLabelLangSpecificFields
}

export interface LabelDescriptionProps {
  description: FormulaNutritionFactLabelLangSpecificFields
}

export const Statements: React.FC<StatementsProps> = ({
  ingredientsStatement,
  allergensStatement,
  labelDescription
}) => {
  const languages = React.useMemo(
    () =>
      Object.values(FormulaNutritionFactLanguage).sort((a, b) => {
        return langDisplaySorter(
          a as FormulaNutritionFactLanguage,
          b as FormulaNutritionFactLanguage
        )
      }),
    []
  )

  const getLanguageData = (language: FormulaNutritionFactLanguage) => ({
    ingredient: {
      statement: ingredientsStatement.statement[language],
      suggestedStatement: ingredientsStatement.suggestedStatement[language],
      prefix: ingredientsStatement.prefix[language]
    },
    allergen: {
      statement: allergensStatement.statementsOverride[language],
      suggestedStatement:
        allergensStatement.suggestedAllergenStatement[language],
      prefix: allergensStatement.allergenStatementPrefix[language]
    },
    description: labelDescription.description[language]
  })

  const languageData = languages.map(getLanguageData)

  return (
    <>
      {languages.map((language, index) => (
        <Box key={language} id={`${language}-statements`}>
          <Statement
            {...languageData[index].ingredient}
            id={`${language}-ingredient-statement`}
          />
          <Statement
            {...languageData[index].allergen}
            id={`${language}-allergen-statement`}
          />
        </Box>
      ))}
      <Box id="description">
        {languageData.map((data, index) => (
          <Typography
            key={languages[index]}
            paragraph={true}
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {data.description}
          </Typography>
        ))}
      </Box>
    </>
  )
}
